import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { GridEditDateCell } from '.';
import { shoppingCartOffersVar } from '../../apollo/reactive-variables/shopping-cart';
import dayjs from '../../config/dayjs';
import { TODAY } from '../../constants';

const PaymentDateEditCell = ({ params, selectedOfferIds }) => {
  const currentOffers = useReactiveVar(shoppingCartOffersVar);

  const dateValue = useMemo(() => {
    const selectedOffer = currentOffers.find(
      ({ invoiceId }) => params.id === invoiceId,
    );
    return selectedOffer?.paymentDate || null;
  }, [currentOffers]);

  const onChange = (row, newValue) => {
    shoppingCartOffersVar([
      { invoiceId: row.id, paymentDate: newValue },
      ...currentOffers.filter((offer) => offer.invoiceId !== row.id),
    ]);
  };

  const onChangeAll = (newValue) => {
    if (selectedOfferIds?.length > 0) {
      const updatedOffers = currentOffers.map((offer) => {
        if (selectedOfferIds.includes(offer.invoiceId)) {
          return { ...offer, paymentDate: newValue };
        }
        return offer;
      });
      shoppingCartOffersVar(updatedOffers);
    } else {
      const updatedOffers = currentOffers.map((offer) => ({
        ...offer,
        paymentDate: newValue,
      }));
      shoppingCartOffersVar(updatedOffers);
    }
  };

  return (
    <GridEditDateCell
      {...params}
      value={dateValue}
      onChange={onChange}
      onChangeAll={onChangeAll}
      minDate={TODAY}
    />
  );
};

PaymentDateEditCell.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    row: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      dateExpiration: PropTypes.objectOf(dayjs),
      offer: PropTypes.shape({
        fundingDaysAfterExpiration: PropTypes.number,
      }),
      receiver: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        invoicesActualPaymentTerm: PropTypes.number,
      }),
    }),
  }).isRequired,
  selectedOfferIds: PropTypes.arrayOf(PropTypes.string),
};

PaymentDateEditCell.defaultProps = {
  selectedOfferIds: [],
};

export default PaymentDateEditCell;
