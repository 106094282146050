/* eslint-disable max-len */

import AccessTime from '@mui/icons-material/AccessTime';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import HelpOutline from '@mui/icons-material/HelpOutline';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Stack, Typography } from '@mui/material';
import { statusToActiveStep, statusToStepLabels } from '../../helpers';
import { useIsMobile } from '../../hooks';
import dayjs from '../../config/dayjs';

export const statusToIcon = {
  PROCESSING: <AccessTime color="warning" />,
  REJECTED: <ErrorOutline color="error" />,
  REJECTEDPREOFFEREVALUATION: <ErrorOutline color="error" />,
  PENDINGRATIFICATION: <AccessTime color="warning" />,
  PENDINGDOCUMENTSREVIEW: <AccessTime color="warning" />,
  REJECTEDRATIFICATION: <ErrorOutline color="error" />,
  PENDINGTRANSFER: <AccessTime color="warning" />,
  REJECTEDTRANSFER: <ErrorOutline color="error" />,
  TRANSFERED: <AccessTime color="warning" />,
  DEBT: <ErrorOutline color="error" />,
  FINISHED: <CheckCircleOutline color="success" />,
  INFORMATION: <HelpOutline style={{ color: '#808080' }} />,
  EXPIRED: <ErrorOutline color="warning" />,
};

export const statusToColor = {
  PROCESSING: 'warning',
  REJECTED: 'error',
  REJECTEDPREOFFEREVALUATION: 'error',
  PENDINGRATIFICATION: 'warning',
  PENDINGDOCUMENTSREVIEW: 'warning',
  REJECTEDRATIFICATION: 'error',
  PENDINGTRANSFER: 'warning',
  REJECTEDTRANSFER: 'error',
  TRANSFERED: 'warning',
  DEBT: 'error',
  FINISHED: 'success',
  INFORMATION: '#808080',
  RECEDED: '#808080',
  EXPIRED: 'warning',
};

const useStyles = makeStyles({
  stepLabel: {
    marginTop: '4px !important',
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
});

const getStatusIcon = (status, i) => {
  if (i < statusToActiveStep[status]) {
    return <CheckCircleOutline color="success" />;
  }
  if (statusToActiveStep[status] === i) return statusToIcon[status];
  return <RadioButtonUnchecked />;
};

const translateCessionType = (type) => {
  if (!type) return null;

  switch (type) {
    case 'EXTERNAL': return 'Externa';
    case 'INTERNAL': return 'Interna';
    default: return type;
  }
};

const getLoanStatusText = (status) => {
  switch (status) {
    case 'LOANED':
      return 'Cedida';
    case 'LOANED_FINGO':
      return 'Cedida a Fingo';
    default:
      return 'Sin ceder';
  }
};

export const Status = ({ status, paymentDate, oneStepper, cessionType, loanedStatus }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const renderStepLabel = (label, i) => {
    const stepLabel = (
      <StepLabel
        icon={getStatusIcon(status, i)}
        classes={{ label: classes.stepLabel }}
      >
        {label}
        <br />
        {status === 'FINISHED'
          && statusToActiveStep[status] === i
          && paymentDate
          && dayjs(paymentDate).format('DD-MM-YYYY')}
      </StepLabel>
    );

    if (status === 'PROCESSING' && statusToActiveStep[status] === i) {
      return (
        <Tooltip
          title={(
            <Stack spacing={0.5}>
              <Typography
                variant="body2"
                sx={{
                  color: 'white',
                  fontWeight: 'medium',
                }}
              >
                {`Tipo de cesión: ${translateCessionType(cessionType)}`}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'white',
                  fontWeight: 'medium',
                }}
              >
                {getLoanStatusText(loanedStatus)}
              </Typography>
            </Stack>
          )}
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                textAlign: 'center',
              },
            },
          }}
        >
          {stepLabel}
        </Tooltip>
      );
    }

    return stepLabel;
  };

  return (
    <Stepper sx={{ p: 0, width: '100%' }} activeStep={-1} alternativeLabel>
      {statusToStepLabels(status, oneStepper).map(
        (label, i) => (((isMobile || oneStepper) && i === statusToActiveStep[status])
            || !(isMobile || oneStepper)) && (
            <Step key={label}>
              {renderStepLabel(label, i)}
            </Step>
        ),
      )}
    </Stepper>
  );
};

Status.propTypes = {
  status: PropTypes.oneOf([
    'CREATED',
    'PROCESSING',
    'PENDINGDOCUMENTSREVIEW',
    'REJECTEDPREOFFEREVALUATION',
    'PENDINGDOCUMENTSEXPIRED',
    'REJECTED',
    'PENDINGDOCUMENTS',
    'PENDINGRATIFICATION',
    'REJECTEDRATIFICATION',
    'PENDINGTRANSFER',
    'REJECTEDTRANSFER',
    'TRANSFERED',
    'DEBT',
    'FINISHED',
    'SURPLUSWITHDRAWN',
    'BADDEBT',
    'LOANEDOTHER',
    'RECEDED',
    'EXPIRED',
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  paymentDate: PropTypes.object,
  oneStepper: PropTypes.bool,
  cessionType: PropTypes.string,
  loanedStatus: PropTypes.string,
};

Status.defaultProps = {
  paymentDate: null,
  oneStepper: false,
  cessionType: null,
  loanedStatus: null,
};
